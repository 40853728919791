.container {
  @media (max-width: 768px) {
    height: auto !important;
  }
}

.modal {
  border-radius: 4px;
  height: 588px;
  width: 540px;

  @media (max-width: 768px) {
    bottom: 0;
    height: 553px;
    left: 0;
    margin: 0 !important;
    position: absolute !important;
    width: 90%;
  }
}

.overlay {
  background-color: rgba(250, 250, 250, 0.86) !important;
}
