@font-face {
  font-family: 'Hurricane';
  src: url('./assets/fonts/Hurricane-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 400;
  src: url('./assets/fonts/ProximaNova-Reg-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 700;
  src: url('./assets/fonts/ProximaNova-Bold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 800;
  src: url('./assets/fonts/ProximaNova-Xbold-webfont.woff2') format('woff2');
}

html {
  scroll-snap-type: y mandatory;
}

body {
  margin: 0;
  font-family: 'ProximaNova', 'Roboto', sans-serif;
}

/* Scrollbar Width */
::-webkit-scrollbar {
  width: 60px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ccc;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background-image: url(./assets/images/honeybee.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
}
